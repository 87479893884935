import { navigationItems } from '@mfe/core-navigation';
import { Priority, registerApp } from '@mfl/framework';
import { currentUser, openUpgradeModal } from '@mfl/platform-shell';
import { strings } from './analytics-page-strings';
import {
  ANALYTICS_ENT,
  ANALYTICS_PERMISSION,
} from '@msl/analytics-page-gateway-sdk';

const route = '/v1/analytics';

export async function register() {
  const ANALYTICS_KEY = 'ANALYTICS';
  const ANALYTICS_ICON = 'chart-mixed';
  const PERSONAL_SIGNATURES_MODE = 'personal-signatures';

  if (
    currentUser.domainId === PERSONAL_SIGNATURES_MODE &&
    !currentUser.isAllowedTo(ANALYTICS_PERMISSION)
  ) {
    return;
  }

  if (currentUser.isEntitledTo(ANALYTICS_ENT)) {
    await registerApp('analytics-page', route);
    navigationItems.register(
      {
        key: ANALYTICS_KEY,
        text: strings.navBarItem,
        fontAwesomeIcon: ANALYTICS_ICON,
        route,
      },
      { priority: Priority.Medium - 60 }
    );
  } else {
    navigationItems.register(
      {
        key: ANALYTICS_KEY,
        text: strings.navBarItem,
        fontAwesomeIcon: ANALYTICS_ICON,
        ornaments: [{ type: 'pro' }],
        handler: () => openUpgradeModal(),
      },
      { priority: Priority.Medium - 60 }
    );
  }
}
